import React, { useEffect } from 'react';
import './Contact.css';
import { Helmet } from 'react-helmet';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
    useEffect(() => {
        const handleScroll = () => {
            const headings = document.querySelectorAll('.column-heading');
            headings.forEach((heading) => {
                if (isElementInViewport(heading)) {
                    heading.classList.add('slide-from-left');
                }
            });
        };

        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

return (
    <div className="contact-page-container">
    <Helmet>
        <title>Contact Us - Di-Tech Marine Engineering</title>
        <meta name="description" content="Contact Di-Tech Marine Engineering for inquiries, workshop location, and contact information. Visit us in Frederikssund, Denmark, or reach us via phone or email." />
        <meta name="keywords" content="Di-Tech Marine Engineering, contact, workshop, Frederikssund, Denmark, marine engineering services, phone, email, location" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Contact Us - Di-Tech Marine Engineering" />
        <meta property="og:description" content="Reach out to Di-Tech Marine Engineering for all your marine engineering service inquiries. Visit our workshop in Frederikssund or contact us via phone or email." />
        <meta property="og:url" content="https://di-tech.eu/contact" />
        <meta property="og:type" content="website" />
    </Helmet>

    <div className="columns-container">
        <div className="contact-info-column">
            <p className='column-heading'>Our workshop in Frederikssund, Denmark</p>
            <div className="contact-info">
                <div className="address-column">
                    <p><FaMapMarkerAlt /></p>
                    <p>Holmensvej 36A,</p>
                    <p>3600 Frederikssund,</p>
                    <p>Denmark</p>
                </div>
                <div className="phone-email-column">
                    <p><FaPhone /> +45 24 21 54 51 / +45 25 73 73 66</p>
                    <p><FaEnvelope /> <a href="mailto:service@di-tech.eu">service@di-tech.eu</a></p>
                </div>
            </div>
        </div>
        <div className="map-column">
            <iframe
                title="location-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1018.9125981752945!2d12.076174196163363!3d55.84813911445046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652691034c79b69%3A0xa159b2e9a6585f4d!2sHolmensvej%2036A%2C%203600%20Frederikssund!5e0!3m2!1sen!2sdk!4v1740577717343!5m2!1sen!2sdk"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    </div>
</div>
);
};

export default Contact;


